.fundraiser-logo {
  height: 150px;
  width: 150px;
  margin-top: -75px;
  border-radius: 100%;
  border: 5px solid $gray-400;
  background-color: $white;
  overflow: hidden;
}

.fundraiser-cover {
  height: 250px;
  background-size: cover;
  background-position: center;
}
