$igiv-blue: #39aaff;
$primary: $igiv-blue;
$yellow: #ffa300;

@import '~bootstrap/scss/bootstrap';
@import 'navigation';
@import 'fundraiser';
@import 'homepage';

$trophy-gold: $yellow;
$trophy-silver: $gray-600;
$trophy-bronze: #a35c33;

html,
body {
  background-color: $gray-200;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

@mixin heavyset {
  font-weight: 900;
  text-transform: uppercase;
}

.fa-start {
  margin-right: 5px;
}

.fa-end {
  margin-left: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn,
.form-label,
.heavyset,
th {
  @include heavyset();
}

.text-transform-none {
  text-transform: none !important;
}

.nav-link {
  font-weight: 900;
  text-transform: uppercase;
  transition: color 0.25s, background-color 0.25s;
}

.stripe-logo {
  color: #635bff;
  vertical-align: middle;
}

// used to push a column to the end of the row on mobile screens
.flex-order-1 {
  order: 1;
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .flex-order-lg-0 {
    order: 0 !important;
  }
}

.leaderboard-table {
  i.first-place {
    color: $trophy-gold;
  }
  i.second-place {
    color: $trophy-silver;
  }
  i.third-place {
    color: $trophy-bronze;
  }

  td {
    vertical-align: middle;
  }
}
