.navbar {
  flex-shrink: 0;
  border-bottom: 1px solid $gray-400;

  .navbar-brand {
    font-weight: 900;
  }

  &.bg-light {
    background-color: $white !important;
  }
}

.footer {
  flex-shrink: 0;
}

.footer-copyright {
  background-color: $gray-900;
}
