.fa-sparkles {
  animation: pulse 0.5s linear infinite alternate;

  &.d2 {
    animation-delay: 0.125s;
    animation-direction: alternate-reverse;
  }
  &.d3 {
    animation-delay: 0.25s;
  }
  &.d4 {
    animation-delay: 0.375s;
    animation-direction: alternate-reverse;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9) rotate(10deg);
  }
  100% {
    transform: scale(1.3) rotate(-5deg);
  }
}
