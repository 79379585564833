img.homepage-fundraiser-example {
  border: 5px solid white;
  border-radius: 5px;

  &:nth-child(1),
  &:nth-child(3) {
    transform: rotate(-7deg);
  }

  &:nth-child(2),
  &:nth-child(4) {
    transform: rotate(3deg);
  }

  @include media-breakpoint-up(xl) {
    &:nth-child(3) {
      transform: rotate(3deg);
    }

    &:nth-child(4) {
      transform: rotate(-5deg);
    }
  }
}
